import {EditorInfo, EntityGrid, Plate} from '@tehzor/ui-components';
import ICompany from '@tehzor/tools/interfaces/companies/ICompany';

interface ILeftSideProps {
	company?: ICompany;
}

const LeftSide = ({company}: ILeftSideProps) =>
	(company ? (
		<Plate>
			<EntityGrid>
				<EditorInfo
					icon={<i className="tz-document-20"/>}
					label="Создана"
					date={company.createdAt}
					user={company.createdBy}
					fullRow
				/>
				{company.createdAt !== company.modifiedAt && (
					<EditorInfo
						icon={<i className="tz-edit-20"/>}
						label="Изменена"
						date={company.modifiedAt}
						user={company.modifiedBy}
						fullRow
					/>
				)}
			</EntityGrid>
		</Plate>
	) : (
		<Plate/>
	));

export default LeftSide;
